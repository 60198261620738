import React, { useEffect } from "react";
import CustomAddButton from "../custom/custom_add_button";
import AdminArticleList from "./admin_article/admin_article_list";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import AdminDetails from "./admin_article/admin_details";
import { Button, DatePicker, Form, Input, Select } from "antd";
import PageTitle from "../system/page_title/page_title";
import { HStack, VStack } from "native-base";
import { navigateWithProps, retrieveItem } from "../../../helpers/functions";
import { ROUTES } from "../../routes/my_routes";
import {
  dynamic_request,
  get_all_languages_query,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { backgroundColor } from "styled-system";
import { article_status } from "../../../helpers/constants";

const AdminArticle = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const role = retrieveItem("role");
  const dispatch = useDispatch();
  const { Search } = Input;
  const { RangePicker } = DatePicker;
  const location = useLocation();

  let new_default_language_id =
    location?.props_state?.current_language_id_select;
  let back_button_true_state = location?.props_state?.back_button_state;

  const new_default_language_name =
    location?.props_state?.back_button_default_language;

  const new_fact_check_type = location?.props_state?.fact_check_type_location;

  const new_article_type = location?.props_state?.new_article_type;

  const [new_default_language_state, set_default_language_state] = useState(
    new_default_language_id
  );

  const [article_modal_open, set_article_modal_open] = useState(false);
  const [modal_title, set_modal_title] = useState("");
  const [form_value, set_form_values] = useState({});
  const [default_language, set_default_language] = useState();
  const [type, set_type] = useState("");
  const [status_option, set_status_option] = useState();
  const [new_back_button_state, set_back] = useState(back_button_true_state);
  const [back_button_click, set_back_button_click] = useState(false);
  const [article_type, set_article_type] = useState(
    new_article_type || "my_article"
  );
  const [search_string, set_search_string] = useState("");
  const [from_date, set_from_date] = useState("");
  const [to_date, set_to_date] = useState("");
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const [language_id, set_language] = useState("" || new_default_language_id);

  const { items: system_languages } = useDynamicSelector(
    "get_all_system_languages"
  );
  let updated_languages;
  if (Array.isArray(system_languages)) {
    updated_languages = [{ id: "", name: "All" }, ...system_languages];
  } else {
  }

  const status = article_status[`${role}_${article_type}`] || [];

  const create_article = () => {
    set_article_modal_open(true);
    set_modal_title("add");
    navigateWithProps(ROUTES.ADMIN_ARTICLE_DETAILS, {
      modal_title: "add",
      record: {},
    });
  };

  const handle_article_modal_close = () => {
    set_article_modal_open(false);
    set_form_values({});
    set_modal_title("");
  };
  useEffect(() => {
    if (!modal_title?.includes("add")) {
      form.setFieldsValue(form_value);
    } else {
      form.resetFields();
    }
  }, [modal_title, form_value]);

  useEffect(() => {
    get_all_languages();
  }, []);

  useEffect(() => {
    if (new_default_language_id) {
      let selected_lang = updated_languages.find(
        (x) => x.id === new_default_language_id
      );
      set_default_language({
        key: selected_lang?.id,
        value: selected_lang?.id,
        children: selected_lang?.name,
      });
    }
  }, [new_default_language_id]);
  const onSearch = (e) => {
    let search_value = e?.target?.value;
    if (search_value?.length >= 0) {
      set_search_string(search_value);
      set_page_number(1);
    }
  };

  const on_change_language = (values, type) => {
    set_default_language_state("");
    back_button_true_state = "";
    set_back(false);
    set_default_language(type);
    set_language(values);
    set_type(type);
  };

  const get_article_type = (value) => {
    set_article_type(value);
  };

  const get_all_languages = () => {
    let key = [{ key: "get_all_system_languages", loading: true }];
    let query = get_all_languages_query;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };
  const set_date_range = (dateString, date) => {
    set_from_date(date[0]);
    set_to_date(date[1]);
  };
  const on_change = (value) => {
    set_status_option(value);
  };

  return (
    <div>
      <PageTitle title={"Articles"} />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
          marginRight: "20px",
        }}
      ></div>
      <VStack style={{ padding: "50px" }}>
        <HStack justifyContent={"flex-end"} mb={"10px"} space={"10px"}>
          <Select
            style={{ width: "180px" }}
            onChange={get_article_type}
            // defaultValue={new_article_type || "my_article"}
            defaultValue={new_article_type ? new_article_type : "my_article"}
          >
            <Option value="my_article">My Article</Option>
            <Option value="user_article">User contribution</Option>
            {role === "Admin" ? (
              <>
                <Option value="editor_article">Editor's Articles</Option>
                <Option value="all_article">Published Articles</Option>
              </>
            ) : (
              ""
            )}
          </Select>
          {article_type != "all_article" && (
            <Select
              onChange={on_change}
              style={{ width: "150px" }}
              placeholder="Status"
              allowClear
            >
              {status?.map((option, index) => (
                <Select.Option key={option.id} value={option.id}>
                  {option?.name}
                </Select.Option>
              ))}
            </Select>
          )}

          <div style={{ width: "160px" }}>
            <RangePicker format="YYYY-MM-DD" onChange={set_date_range} />
          </div>
          <Search
            enterButton={true}
            onSearch={onSearch}
            allowClear={true}
            onChange={onSearch}
            placeholder="Search"
            style={{ width: "250px", backgroundColor: "white" }}
            className="admin-list-search"
          />
          <Select
            onChange={on_change_language}
            defaultValue={new_default_language_name || "All"}
            style={{ width: "18vh" }}
          >
            {updated_languages?.map((option, index) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
          {/* {article_type === "my_article" && ( */}
          <CustomAddButton
            button_name={t("add_article")}
            onclick_button={create_article}
          />
          {/* )} */}
        </HStack>

        <AdminArticleList
          type={type}
          set_article_modal_open={set_article_modal_open}
          set_modal_title={set_modal_title}
          set_form_values={set_form_values}
          language_id={language_id}
          article_type={article_type}
          set_article_type={set_article_type}
          search_string={search_string}
          from_date={from_date}
          to_date={to_date}
          default_language={default_language}
          set_back_button_click={set_back_button_click}
          back_button_true_state={back_button_true_state}
          new_default_language_state={new_default_language_state}
          new_back_button_state={new_back_button_state}
          status_option={status_option}
          page_number={page_number}
          page_limit={page_limit}
          set_page_limit={set_page_limit}
          set_page_number={set_page_number}
        />
      </VStack>
    </div>
  );
};

export default AdminArticle;
