import { gql } from "@apollo/client";

export const query_get_all_article = gql`
  query get_all_article(
    $page_number: Int
    $page_limit: Int
    $json: get_all_article_input
  ) {
    get_all_article(
      page_number: $page_number
      page_limit: $page_limit
      json: $json
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        language_id
        log_count
        staff_profile_id
        user_profile_id
        staff_profile {
          id
          name
        }
        user_profile {
          id
          name
        }
        thumbnail_img
        is_saved
        title
        subtitle
        perma_link
        description
        content
        status
        reading_time
        likes
        views
        comments_count
        is_reported
        is_deleted
        created_date_time
        published_date_time
        category_tags {
          id
          name
        }
        party_tags {
          id
          name
          language_id
        }
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_top_wise_article = gql`
  query get_all_featured_story($language_id: String!) {
    get_all_featured_story(language_id: $language_id) {
      items {
        type
        language_id
        fact_check_id
        fact_check {
          id
          title
          subtitle
          views
          thumbnail_img
        }
        article_id
        article {
          id
          language_id
          title
          subtitle
          reading_time
          thumbnail_img
          views
          created_date_time
          perma_link
          user_profile {
            id
            name
          }
        }
        youtube_video_id
        youtube_video {
          title
        }
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_first_article_query = gql`
  query get_all_article(
    $page_number: Int
    $page_limit: Int
    $json: get_all_article_input
  ) {
    get_top_first_article: get_all_article(
      page_number: $page_number
      page_limit: $page_limit
      json: $json
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        language_id
        staff_profile_id
        user_profile_id
        staff_profile {
          id
          name
        }
        user_profile {
          id
          name
        }
        thumbnail_img
        is_saved
        title
        subtitle
        perma_link
        description
        content
        status
        reading_time
        likes
        views
        comments_count
        is_reported
        is_deleted
        created_date_time
        published_date_time
      }
      error {
        status_code
        message
      }
    }
  }
`;

// export const query_get_top_wise_article = gql`
//   query get_all_article(
//     $page_number: Int
//     $page_limit: Int
//     $json: get_all_article_input
//   ) {
//     get_top_article: get_all_article(
//       page_number: $page_number
//       page_limit: $page_limit
//       json: $json
//     ) {
//       items {
//         id
//         thumbnail_img
//         staff_profile_id
//         user_profile_id
//         staff_profile {
//           id
//           name
//         }
//         title
//         subtitle
//         perma_link
//         description
//         is_saved
//         content
//         status
//         reading_time
//         likes
//         views
//         comments_count
//         is_reported
//         is_deleted
//         created_date_time
//       }

//       error {
//         status_code
//         message
//       }
//     }
//   }
// `;
export const mutation_create_article = gql`
  mutation create_article($json: article_input) {
    create_article(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_article = gql`
  mutation update_article($json: article_input, $id: String) {
    update_article(json: $json, id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_article_in_auto_save = gql`
  mutation auto_save_article($json: article_input, $id: String) {
  update_article_in_auto_save: auto_save_article(json: $json, id: $id) {
    id
    status
    error {
      status_code
      message
    }
  }
}
`;

export const mutation_delete_article = gql`
  mutation delete_article($id: String) {
    delete_article(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_article_by_perma_link = gql`
  query get_article_by_perma_link($perma_link: String!, $language_id: String!) {
    get_article_by_perma_link(
      perma_link: $perma_link
      language_id: $language_id
    ) {
      id
      staff_profile_id
      user_profile_id
      staff_profile {
        id
        name
        profile_pic
        url
      }
      user_profile {
        id
        name
        profile_pic
        url
      }
      category_tags {
        id
        name
      }
      title
      subtitle
      perma_link
      is_saved
      description
      content
      status
      reading_time
      likes
      views
      comments_count
      is_reported
      is_deleted
      thumbnail_img
      created_date_time
      published_date_time
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_article_by_perma_link_for_bookmark = gql`
  query get_article_by_perma_link($perma_link: String!) {
    get_article_by_perma_link_for_bookmark: get_article_by_perma_link(
      perma_link: $perma_link
    ) {
      id

      is_saved
    }
  }
`;
export const query_get_all_category_wise_article = gql`
  query get_all_article(
    $page_number: Int
    $page_limit: Int
    $json: get_all_article_input
  ) {
    get_all_category_wise_article: get_all_article(
      page_number: $page_number
      page_limit: $page_limit
      json: $json
    ) {
      items {
        id
        thumbnail_img
        staff_profile_id
        user_profile_id
        title
        subtitle
        perma_link
        description
        content
        is_saved
        status
        reading_time
        likes
        views
        comments_count
        is_reported
        is_deleted
        created_date_time
      }

      error {
        status_code
        message
      }
    }
  }
`;
export const trending_article_query = gql`
  query get_trending_articles($language_id: String!) {
    get_trending_articles(language_id: $language_id) {
      items {
        id
        staff_profile_id
        user_profile_id
        staff_profile {
          id
          name
        }
        user_profile {
          id
          name
        }
        title
        subtitle
        perma_link
        description
        content
        status
        reading_time
        likes
        views
        comments_count
        is_reported
        is_deleted
        thumbnail_img
        created_date_time
        published_date_time
        user_profile {
          name
        }
      }
    }
  }
`;

export const recommended_stories_query = gql`
  query get_all_recommended_story($language_id: String!) {
    get_all_recommended_story(language_id: $language_id) {
      items {
        ref_id
        fact_check_id
        fact_check {
          id
          title
          staff_profile {
            id
            name
          }
          user_profile {
            id
            name
          }
          subtitle
          created_date_time
          published_date_time
          thumbnail_img
          perma_link
          content
          reading_time
          views
        }
        article_id
        article {
          id
          title
          staff_profile {
            id
            name
          }
          subtitle
          created_date_time
          published_date_time
          thumbnail_img
          perma_link
          content
          reading_time
          views
        }
      }
    }
  }
`;

export const article_process_query = gql`
  mutation process_article(
    $id: String!
    $type: String!
    $published_date_time: String
    $notify_post:Boolean
  ) {
    process_article(
      id: $id
      type: $type
      published_date_time: $published_date_time
      notify_post:$notify_post
    ) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_all_approval_articles_query = gql`
  query get_all_previleged_user_article(
    $json: get_all_article_input
    $page_number: Int
    $page_limit: Int
  ) {
    get_all_previleged_user_article(
      json: $json
      page_number: $page_number
      page_limit: $page_limit
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        language_id
        log_count
        title
        subtitle
        perma_link
        status
        created_date_time
        published_date_time
        category_tags {
          id
          name
        }
        party_tags {
          id
          name
          language_id
        }
        staff_profile {
          id
          name
        }
        user_profile {
          id
          name
        }
      }
    }
  }
`;

export const get_all_admin_history_query = gql`
  query get_all_article_history(
    $article_id: String
    $page_number: Int
    $page_limit: Int
  ) {
    get_all_article_history(
      article_id: $article_id
      page_number: $page_number
      page_limit: $page_limit
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        title
        subtitle
        perma_link
        description
        content
        content_json
        article_id
        thumbnail_img
        status
        created_date_time
        error {
          message
        }
      }
    }
  }
`;

export const get_article_history_query=gql`
query get_article_history($id:String!){
  get_article_history(id:$id){
    id
    title
    subtitle
    perma_link
    description
    content
    content_json
    thumbnail_img
    created_date_time
    published_date_time
    language_id
    status
    error{
      status_code
      message
    }
  }
}`

export const get_all_my_articles_query = gql`
  query get_all_my_article(
    $category_tag: String
    $language_id: String
    $page_number: Int
    $page_limit: Int
    $status: String
    $title: String
    $from_date: String
    $to_date: String
  ) {
    get_all_my_article(
      category_tag: $category_tag
      page_number: $page_number
      page_limit: $page_limit
      language_id: $language_id
      status: $status
      title: $title
      from_date: $from_date
      to_date: $to_date
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        language_id
        log_count
        title
        subtitle
        perma_link
        thumbnail_img
        language_id
        status
        created_date_time
        published_date_time
        category_tags {
          id
          name
          language_id
        }
        user_profile {
          id
          name
        }
        staff_profile {
          id
          name
        }
        party_tags {
          id
          name
          language_id
        }
        user_profile_id
        staff_profile_id
      }
    }
  }
`;

export const get_all_editors_article_query = gql`
  query get_all_editor_article(
    $json: get_all_article_input
    $page_number: Int
    $page_limit: Int
  ) {
    get_all_editor_article(
      json: $json
      page_number: $page_number
      page_limit: $page_limit
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        language_id
        log_count
        title
        subtitle
        perma_link
        status
        reading_time
        created_date_time
        published_date_time
        category_tags {
          id
          name
        }
        party_tags {
          id
          name
          language_id
        }
        user_profile {
          id
          name
        }
        staff_profile {
          id
          name
        }
      }
    }
  }
`;

export const save_article_query = gql`
  mutation save_article($json: article_input) {
    save_article(json: $json) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const get_all_published_article_query = gql`
  query get_all_published_article(
    $page_number: Int
    $page_limit: Int
    $json: get_all_article_input
  ) {
    get_all_published_article(
      page_number: $page_number
      page_limit: $page_limit
      json: $json
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        language_id
        log_count
        staff_profile_id
        user_profile_id
        staff_profile {
          id
          name
        }
        user_profile {
          id
          name
        }
        title
        subtitle
        perma_link
        status
        is_reported
        is_deleted
        created_date_time
        published_date_time
        category_tags {
          id
          name
        }
        party_tags {
          id
          name
          language_id
        }
      }
      error {
        status_code
        message
      }
    }
  }
`;
